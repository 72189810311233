import './bootstrap';

window.Vue = import('vue').withDefaults;

import { createApp, markRaw } from 'vue/dist/vue.esm-bundler'
import { TailwindPagination } from 'laravel-vue-pagination'
import { defineRule } from 'vee-validate'
import router from './router/index'
import Editor from '@tinymce/tinymce-vue'
import SubmitButton from './components/micro/SubmitButton.vue'
import SideBarSubmitButton from './components/micro/SideBarSubmitButton.vue'
import BaseFormInput from './components/micro/BaseFormInput.vue'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'
import { createPinia } from 'pinia'
import i18n from './i18n/index'
import helper from './helpers'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPaperPlaneTop as fasPaperPlaneTop, faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'
import { faPaperPlaneTop as farPaperPlaneTop, faTrash, faBan, faGear, faLink, faCircleXmark,
        faUpload, faRotateRight, faRotateLeft, faPaperclip, faCircleExclamation, faLocationDot, faTag, faList, faCreditCard,
        faUnlock, faRocket, faSquareQuestion, faSunBright, faMagnifyingGlass, faFileCirclePlus, faBrainCircuit, faCheck, faXmark,
        faPlaneSlash, faPlane, faFaceUnamused } from '@fortawesome/pro-regular-svg-icons'
import { faPenToSquare, faCopy, faFloppyDisk, faFile, faAddressBook, faUser as farUser, faLightbulb, faMessage, faFaceMeh,
        faFaceSmile, faFaceFrown, faStar, faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faUser as fasUser, faCalendarDays, faMoon, faArrowRightFromBracket, faFileExcel, faBuilding as fasBuilding } from '@fortawesome/free-solid-svg-icons'
import * as Sentry from "@sentry/vue";


library.add(fasPaperPlaneTop, farPaperPlaneTop, faTrash, faPenToSquare, faBan, faGear, faLink, faCircleXmark,
            faArrowUpRightFromSquare, faCopy, faFloppyDisk, faUpload, faRotateRight, faRotateLeft, faFile, faAddressBook, farUser,
            fasUser, faPaperclip, faCircleExclamation, faLocationDot, faTag, faList, faCreditCard, faCalendarDays,
            faUnlock, faRocket, faSquareQuestion, faMoon, faLightbulb, faSunBright, faMessage, faArrowRightFromBracket,
            faMagnifyingGlass, faFileCirclePlus, faBrainCircuit, faCheck, faXmark, faFileExcel, faPlaneSlash, faPlane, faFaceMeh,
            faFaceSmile, faFaceFrown, faFaceUnamused, faStar, faBuilding, fasBuilding)



// Interceptor to redirect front-end when user session times out
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error);
    // Admin redirect when session expires
    if (error.response.data.message.includes('admin')) {
        window.location.href = '/admin/login'
    }

    // User redirect when session expires
    else if (error.response.data.message.includes('Unauthenticated')) {
        window.location.href = '/'
    }


    // Return all other errors
    return Promise.reject(error);

});

// Form Validation rules
defineRule('required', value => {
    if (!value || !value.length) {
      return 'This field is required';
    }
    return true;
});

defineRule('shortRequired', value => {
    if (!value || !value.length) {
        return 'Required';
    }
    return true;
});

defineRule('length', (value, [length]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
      return true;
    }
    if (value.length > length) {
      return `This field must be ${length} or less characters`;
    }
    return true;
});

defineRule('min-length', (value, [length]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }
    if (value.length < length) {
        return `This field must be ${length} or more characters`;
    }
    return true;
});

defineRule('email', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }

    // JavaScript code for email validation
    // without using regular expression
    var atSymbol = value.indexOf("@");
    var dotSymbol = value.lastIndexOf(".");
    var spaceSymbol = value.indexOf(" ");

    if ((atSymbol !== -1) &&
        (atSymbol !== 0) &&
        (dotSymbol !== -1) &&
        (dotSymbol !== 0) &&
        (dotSymbol > atSymbol + 1) &&
        (value.length > dotSymbol + 1) &&
        (spaceSymbol === -1)) {
        // alert("Email address is valid.");
        return true;
    } else {
      return 'This field must be a valid email';
    }

    // return true;
});
defineRule('confirmed', (value, [target], ctx) => {
    if (value === ctx.form[target]) {
      return true;
    }
    return 'Passwords must match';
  });

defineRule('phone', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }
    // Check if phone
    if (!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/i.test(value)) {
      return 'This field must be a valid phone number';
    }
    return true;
});

const app = createApp({})

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    tracesSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration({ router })],
    trackComponents: true
});

const pinia = createPinia()
pinia.use(({store}) => {
    store.router = markRaw(router)
})


app.use(router)
app.use(i18n)
app.use(helper)
app.use(LaravelPermissionToVueJS)
app.use(pinia)

app.component('Pagination', TailwindPagination);
app.component('editor', Editor);
app.component('submit-button', SubmitButton);
app.component('sidebar-submit-button', SideBarSubmitButton);
app.component('base-form-input', BaseFormInput);
app.component('rich-text', RichTextRenderer);
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app');

